.card.card-faq {
  border-radius: 0;

  .card-header {
    border-radius: 0;
  }

  .card-body {
    box-shadow: none;
  }
}
