.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
  background: $backdrop-bg;
  opacity: $backdrop-opacity;
  animation: fade-in 200ms ease-in;
}

.menu-backdrop {
  z-index: $zindex-navbar - 1;
  @extend .backdrop;
}

@keyframes fade-in {
  from { opacity: 0; }
}
