.line-container {
  position: relative;

  .horizontal-line {
    position: absolute;
    top: $circle-sm-horizontal-margin;
    left: $circle-sm-width;
    width: 75%;
    border: 0;
    border-top: $line-bold dashed $accent-lighter;

    @include media-breakpoint-up(lg) {
      top: $circle-horizontal-margin;
      left: $circle-width;
    }

    &.horizontal-line-lg {
      top: $circle-lg-horizontal-margin;
      left: $circle-lg-width;
      width: 65%;

      @include media-breakpoint-up(lg) {
        top: $circle-lg-horizontal-margin-desktop;
        left: $circle-lg-width-desktop;
      }
    }
  }

  .vertical-line {
    position: absolute;
    top: $circle-sm-height;
    left: $circle-sm-vertical-margin;
    height: 70%;
    border: 0;
    border-left: $line-bold dashed $accent-lighter;

    &.vertical-line-lg {
      top: $circle-lg-height;
      left: $circle-lg-vertical-margin;
    }
  }
}

.circle {
  position: relative;
  z-index: $zindex-circle;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $circle-sm-width;
  height: $circle-sm-height;
  font-weight: $font-weight-bold;
  color: $accent;
  border: solid 2px $accent;
  border-radius: $border-radius-circle;

  @include media-breakpoint-up(lg) {
    width: $circle-width;
    height: $circle-height;
  }

  &.circle-sm {
    width: $circle-sm-width;
    height: $circle-sm-height;
  }

  &.circle-lg {
    width: $circle-lg-width;
    height: $circle-lg-width;

    img {
      width: 40px;
      height: 40px;
    }

    @include media-breakpoint-up(lg) {
      img {
        width: $circle-lg-width;
        height: $circle-lg-width;
      }
      width: $circle-lg-width-desktop;
      height: $circle-lg-width-desktop;
    }
  }

  &.circle-shadow {
    position: absolute;
    top: .25rem;
    left: 1.25rem;
    z-index: $zindex-circle-shadow;
    background-color: $accent-lighter;
    border: 0;
  }
}

.line-separator {
  width: 100%;
  height: 1px;
}
