.pagination {
  justify-content: center;
  font-family: $font-family-sofia-pro;
  font-size: $font-size-sm;
}

.page-item {
  .page-link {
    min-width: 2.125rem;
    text-align: center;
    border: 1.5px solid transparent;

    @extend .d-flex;
    @extend .justify-content-center;

    .arrow-dropdown-toggle::after {
      vertical-align: middle;
    }
  }

  .page-text {
    padding: $pagination-padding-y $pagination-padding-x;
    margin-left: -$pagination-border-width;
  }

  &:hover .page-link {
    background-color: $gray-300;
    border-color: $gray-300;
  }

  &:first-child,
  &:last-child {
    &:disabled,
    &.disabled {
      opacity: .65;
    }
    &:not(.disabled):hover .page-link {
      border-color: $gray-500;
    }
    .page-link {
      color: $primary;
      background-color: transparent;
      border-color: $gray-400;
    }
  }
  &:first-child {
    .page-link {
      margin-right: 5px;
    }
  }
  &:last-child {
    .page-link {
      margin-left: 5px;
    }
  }

  &.active.disabled:not(:first-child):not(:last-child) .page-link {
    font-weight: $font-weight-bold;
    color: $pagination-active-color;
    background-color: $pagination-active-bg;
    border-color: transparent;
  }
}

// Make space for the navigation component below the card
.navigation-panel {
  margin-bottom: 5rem;
}
