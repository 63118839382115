.popover {
  &.sd-popover {

    max-width: 312px;
    border-style: none;
    border-radius: 0;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, .15);

    .popover-header {
      display: flex;
      justify-content: center;
      padding: 1.5rem;
      background-color: $accent-lighter;
      border-radius: 0;

      &::before {
        border-bottom-color: $accent-lighter;
      }
    }

    .popover-body {
      padding: 1rem;
    }

    .arrow::before {
      border-top-color: $white;
      border-bottom-color: $white;
    }
  }

  &.bs-popover-bottom {
    .arrow::after {
      border-bottom-color: $accent-lighter;
    }

    .arrow::before {
      border-top-color: $accent-lighter;
      border-bottom-color: $accent-lighter;
    }
  }
}

