.category-search-input {
  z-index: $zindex-category-search + 1;

  ~ .icon-clear {
    z-index: $zindex-category-search + 3;
  }
  ~ .icon-loading {
    z-index: $zindex-category-search + 4;
  }
}

.category-search-backdrop {
  z-index: $zindex-category-search;
}

.label-description {
  font-size: $font-size-sm;
}

.form-header {
  font-size: 1.5rem;

  @include media-breakpoint-up(md) {
    font-size: 2.375rem;
  }
}
// stylelint-disable selector-no-qualifying-type

.radio-boxed {
  position: relative;
  label {
    display: inline-block;
    width: 40px;
    height: 40px;
    padding: 7px 0;
    margin-bottom: 0;
    font-weight: $font-weight-normal;
    text-align: center;
    cursor: pointer;
    background-color: $white;
    border: solid 1px $gray-400;
    border-radius: $border-radius;
    box-shadow: inset 0 -1px 0 0 $gray-400;

    &:hover:not(:checked) {
      background-color: $gray-200;
    }
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"]:checked + label {
    font-weight: $font-weight-bold;
    color: $white;
    background-color: $primary;
    border: solid 1px $primary;
    box-shadow: inset 0 -1px 0 0 $primary;
  }
}

.form-control {
  &.is-invalid {
    background-image: $custom-icon-invalid;
  }
}

.custom-control.control-checkbox-btn {
  padding-left: 0;
  background-color: $white;
  @extend .border;

  label {
    padding: .75rem 0 .75rem 2.5rem;
    color: $gray-800;
    cursor: pointer;
    @extend .font-size-md;

    &::before,
    &::after {
      top: 1rem;
      left: 1rem;
    }
  }

  &.active {
    font-weight: $font-weight-bold;
    background-color: $accent-lighter;
  }
}

.input-group-append {
  input {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .append {
    border-left: none;
    .input-group-text {
      justify-content: center;
      min-width: $append-min-width;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      &.has-error {
        border-color: $danger;
      }
    }
  }

  input[type="number"] {
    max-width: 120px;
  }
}
// stylelint-disable property-no-vendor-prefix

input.hide-arrows {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
}

.form-headline {
  @extend .h2;
  margin-bottom: 1rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 1.5rem;
  }
}

.input-lg {
  height: 54px;
  font-size: 18px;
}
