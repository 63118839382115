// stylelint-disable declaration-no-important
.panel {
  position: relative;
  padding: $panel-padding-small;
  background-color: $white;
  border-radius: $border-radius;

  @include media-breakpoint-up(md) {
    padding: $panel-padding-medium;
  }

  @include media-breakpoint-up(lg) {
    padding: $panel-padding-large;
  }

  &.panel-shadowed {
    box-shadow: $box-shadow-lg;
  }

  &.panel-bordered {
    border: $border-width solid $border-color;
  }

  &.panel-top {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.panel-middle {
    border-top: 0;
    border-radius: 0;
  }

  &.panel-bottom {
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.quote {
    background-image: url("../images/icon-40-quote-grey-300.svg");
    background-repeat: no-repeat;
    background-position: 10px 10px;
    background-size: 29px 28px;
  }

  .panel-heading {
    color: $gray-800;
    svg {
      fill: $gray-800;
    }
  }


}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .panel#{$infix}-small {
      padding: $panel-padding-small !important;
    }
    .panel#{$infix}-medium {
      padding: $panel-padding-medium !important;
    }
    .panel#{$infix}-large {
      padding: $panel-padding-large !important;
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $direction in $directions {
      .border-#{$direction}#{$infix} {
        border-#{$direction}: $border-width solid $border-color !important;
      }

      .border-#{$direction}#{$infix}-0 {
        border-#{$direction}: 0 !important;
      }
    }
  }
}
$box-shadow-lg-size: .5rem !default;

// stylelint-disable selector-no-qualifying-type
// An anchor has to behave different when being a clickable panel
a.panel {
  display: flex;
  color: $text-color;

  &:hover {
    color: $text-color;
    text-decoration: none;
  }

  &:active {
    font-weight: $font-weight-normal;
  }

  &.panel-bordered:active {
    border-color: $gray-400;
  }
  &.panel-shadowed:active {
    box-shadow: $box-shadow-lg-size $box-shadow-lg-size 0 0 rgba(20, 48, 102, .05);
  }
}
// stylelint-enable selector-no-qualifying-type

.panel-position-absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
}

@include media-breakpoint-down(xs) {
  .mobile-negative-bottom-margin {
    margin-bottom: -55px;
  }
}

.clickable {
  text-decoration: none;
  cursor: pointer;
  &:hover {
    font-weight: $font-weight-normal;
  }
  &:focus {
    font-weight: $font-weight-normal;
  }
}

.underline-text:hover {
  text-decoration: underline;
}

.panel.panel-xs {
  max-width: 408px;
  padding: 1rem;
  border-radius: 0;

  @include media-breakpoint-up(md) {
    padding: 2rem;
  }
}
