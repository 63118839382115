$postcode-hero-navigation-navbar-max-width: 1440px !default;

.nav-link {
  display: flex;
  padding: $nav-link-padding-y $nav-link-padding-x;

  @include media-breakpoint-up(lg) {
    margin-left: 1rem;
  }

  &.btn {
    padding: $nav-link-padding-y 2rem;
  }
}

@keyframes absoluteMenuAnimationOpen {
  0% {
    max-height: 72px;
    background-color: rgba(255, 255, 255, 0);
  }
  33% {
    max-height: 72px;
    background-color: rgba(255, 255, 255, 1);
  }
  100% {
    max-height: 100vh;
    background-color: rgba(255, 255, 255, 1);
  }
}
@keyframes absoluteMenuAnimationClose {
  0% {
    max-height: 100vh;
    background-color: rgba(255, 255, 255, 1);
  }
  66% {
    max-height: 72px;
    background-color: rgba(255, 255, 255, 1);
  }
  100% {
    max-height: 72px;
    background-color: rgba(255, 255, 255, 0);
  }
}

.absolute-menu {
  &.closing {
    overflow: hidden;
    animation-name: absoluteMenuAnimationClose;
    animation-duration: 250ms; // when updating, also update the seo-menu.ts animation end timeout
    animation-timing-function: ease-out;
  }

  &.opening {
    overflow: hidden;
    animation-name: absoluteMenuAnimationOpen;
    animation-duration: 250ms;
    animation-timing-function: ease-in;
  }

  .market-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $market-header-height;

    @include media-breakpoint-up(lg) {
      height: $market-header-height-desktop;
    }

    .header-image {
      height: $market-header-img-height;
    }
  }
}

.homedeal-navbar {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: -12px; //So the last link also has a padding of 12px to be easier clickable and better hover state

  @extend .navbar-height;

}

.registration-flow-navbar {
  height: $registration-flow-header-height;
  padding: 0;
  .navbar-brand {
    padding: 0;
  }
}

.registration-flow-footer {
  background-color: $gray-150;
  border-top: solid 1px $gray-300;
}

.footer {
  padding-top: 3.5rem;
  margin-top: 3.5rem;
  background-color: $gray-150;
}

.page-navigation-navbar {
  display: none;
  @include media-breakpoint-up(lg) {
    display: flex;
  }
  @extend .navbar;
  padding-right: 0;
  padding-left: 0;
  background-color: $page-navigation-navbar-background-color;
  box-shadow: $page-navigation-navbar-box-shadow;

  .nav-link {
    padding: 0 $page-navigation-navbar-nav-link-padding-x 0 $page-navigation-navbar-nav-link-padding-x;
    margin-left: 0;
    color: $gray-800;

    .nav-text {
      padding: $page-navigation-navbar-nav-link-padding-top 0 $page-navigation-navbar-nav-link-padding-bottom 0;
    }

    &.active {
      color: initial;
      background-color: transparent;

      .nav-text {
        color: $gray-800;
        box-shadow: $page-navigation-navbar-nav-link-active-box-shadow;
      }
    }

    &:hover:not(.active) {
      .nav-text {
        box-shadow: $page-navigation-navbar-nav-link-hover-box-shadow;
      }
    }
  }

  &.expanded {
    .nav-text {
      padding: $page-navigation-navbar-nav-link-padding-top-expanded 0 $page-navigation-navbar-nav-link-padding-bottom-expanded 0;
    }
  }
}

.b2b-navbar {
  @extend .page-navigation-navbar;
  z-index: $zindex-b2b-navbar;
  .b2b-navbar-cta {
    display: none;
  }

  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;

    @include media-breakpoint-up(xl) {
      .b2b-navbar-cta {
        display: flex;
      }
    }
    // stylelint-disable-next-line scss/selector-no-redundant-nesting-selector
    & + .content {
      padding-top: 64px;
    }
  }
}

.postcode-hero-navigation-navbar {
  @extend .page-navigation-navbar;
  z-index: auto;
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @include media-breakpoint-up(xl) {
    max-width: $postcode-hero-navigation-navbar-max-width;
  }
}

.header-search-input {
  padding: $input-padding-y $input-padding-x;
  font-size: $font-size-base;
  line-height: $input-line-height;
  @include border-radius($input-border-radius-lg);

  @include media-breakpoint-up(lg) {
    padding: $input-padding-y-lg $input-padding-x-lg;
    font-size: $font-size-lg;
    line-height: $input-line-height-lg;
    @include border-radius($input-border-radius-lg);
  }
}

.header-search-button {
  margin-left: .5rem;
  font-size: $font-size-base;
  line-height: $input-line-height;
  @include border-radius($btn-border-radius);

  @include media-breakpoint-up(lg) {
    padding: $input-padding-y-lg $input-padding-x-lg;
    font-size: $font-size-lg;
    line-height: $input-line-height-lg;
    @include border-radius($btn-border-radius-lg);
  }
}

.navbar-brand {
  margin-right: 0;
}

.panel-warning {
  padding: 20px;
  font-size: $font-size-sm;
  background-color: $gray-100;
}
