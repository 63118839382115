// stylelint-disable declaration-no-important
.edit-photo-dropdown {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 20;

  .dropdown-menu {
    overflow: hidden;

    &[x-placement="top-end"] {
      top: -.3125rem !important;
    }
    &[x-placement="bottom-end"] {
      top: .3125rem !important;
    }
  }
}

.dropdown-menu {
  border: 0;
  box-shadow: $all-border-shadow;
  .dropdown-item {
    border-color: $gray-300;

    &:last-of-type {
      border-bottom: none;
    }
  }
}

.dropdown .btn {
  border-radius: $input-border-radius;
}
