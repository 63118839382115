.banner {
  display: flex;
  flex-direction: row;
  padding-left: $banner-padding;
  overflow: hidden;
  background-color: $accent-lighter;
  border-radius: $border-radius;
  @include media-breakpoint-up(md) {
    padding-right: $banner-padding-x-md;
    padding-left: $banner-padding-x-md;
  }

  .banner-body {
    padding-top: $banner-padding;
    padding-bottom: $banner-padding;
    @include media-breakpoint-up(md) {
      padding-top: $banner-padding-y-md;
      padding-bottom: $banner-padding-y-md;
    }

    .banner-title {
      @extend .h2;
      padding-right: 1.25rem;
      margin-bottom: $banner-title-margin-bottom;
    }

    .banner-text {
      @extend .p-large;
      @extend .text-gray;
      width: $banner-text-width;
      margin-bottom: $banner-text-margin-bottom;
      @include media-breakpoint-up(md) {
        margin-bottom: $banner-text-margin-bottom-md;
      }
    }
  }

  &.banner-sm {
    padding-left: $banner-padding;
    padding-right: $banner-padding;

    .banner-body {
        padding-top: $banner-padding;
        padding-bottom: $banner-padding;
    }
  }

  .banner-image {
    position: relative;
    display: flex;
    align-items: flex-end;
    img {
      position: absolute;
      right: $banner-image-position-right;
      bottom: $banner-image-position-bottom;
      max-height: $banner-image-max-height;
      @include media-breakpoint-up(md) {
        right: $banner-image-position-right-md;
        bottom: $banner-image-position-bottom-md;
        max-height: $banner-image-max-height-md;
      }
      @include media-breakpoint-up(lg) {
        position: relative;
        max-height: $banner-image-max-height-lg;
      }
    }
  }
}
