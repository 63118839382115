// stylelint-disable declaration-no-important

.border-light {
  border: 1px solid $gray-300 !important;
}

.border-bold {
  border-width: 2px !important;
}

.border-radius-bottom {
  border-bottom-right-radius: $card-border-radius;
  border-bottom-left-radius: $card-border-radius;
}

.border-radius {
  border-radius: $card-border-radius;
}

.border-radius-top {
  border-top-left-radius: $card-border-radius;
  border-top-right-radius: $card-border-radius;
}

.border-shadow {
  box-shadow: $all-border-shadow;
}

.border-bottom-last:last-of-type {
  border-bottom: $border-width solid $border-color;
}

.border-bottom-md-right {
  border-bottom: $border-width solid $border-color;

  @include media-breakpoint-up(md) {
    border-right: $border-width solid $border-color;
    border-bottom: none;
  }
}

.border-right-odd:nth-child(odd) {
  @include media-breakpoint-up(md) {
    border-right: $border-width solid $border-color;
  }
}

@each $color, $value in $grays {
  .border-dashed-gray-#{$color} {
    border: 2px dashed $value;
  }
}

.outline-primary-bold {
  outline: 2px solid $primary !important;
}

