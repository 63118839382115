$call-to-action-margin-left-sm: 2rem !default;
$call-to-action-margin-left-lg: 4rem !default;
$call-to-action-margin-left-xl: 5rem !default;

.call-to-action {
  @extend .panel;
  @extend .panel-small;
  @extend .panel-md-medium;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .call-to-action-title {
    margin: 0;
  }

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    align-items: center;
  }

  .call-to-action-button {
    margin-top: 1rem;

    @include media-breakpoint-up(sm) {
      margin-top: 0;
      margin-left: $call-to-action-margin-left-sm;
    }

    @include media-breakpoint-up(lg) {
      margin-left: $call-to-action-margin-left-lg;
    }

    @include media-breakpoint-up(xl) {
      margin-left: $call-to-action-margin-left-xl;
    }
  }
}
