// stylelint-disable declaration-no-important

.navigation-list-toggle {
  @extend .list-element;
  @include arrow("after", $gray-800, bottom);
  display: block;
  padding-top: .5rem;
  padding-bottom: .5rem;
  @include media-breakpoint-up(lg) {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: .75rem;
  }

  color: $gray-700;

  &:hover {
    color: $gray-800 !important;
    text-decoration: underline !important;
  }
}

.nav-list-element {
  @extend .list-element;
  @extend .text-accent;
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;

  padding-top: .5rem;
  padding-bottom: .5rem;

  &.active {
    font-weight: $font-weight-bold;

    &::before {
      position: absolute;
      left: 0;
      width: 4px;
      height: $line-height-base * $font-size-base;
      content: "";
      background-color: $accent;
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: .75rem;
  }
}

.region-col {
  &:last-child {
    .custom-control {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .custom-control {
    margin-bottom: 1rem;

    &:last-child {
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
  }
}

.list-bold {
  li::marker {
    font-weight: $font-weight-bold;
  }
}
