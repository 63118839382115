.usps-list {
  .usp-item {
    &:last-child {
      margin-bottom: 0;
    }

    img {
      width: $icon-width;
      height: $icon-height;
    }
  }
}
