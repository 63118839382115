.anonymous-review-question-icon {
  width: 16px;
  height: 16px;
  margin-left: 4px;
  font-size: 12px;
  line-height: 1.4;
  color: $white;
  text-align: center;
  background-color: $secondary;
  border: 1px solid $secondary;
  border-radius: 50%;
  &.active,
  &[aria-describedby]{
    color: $secondary;
    background-color: $white;
  }
  &:hover {
    cursor: pointer;
  }
}
