.choice-container {
  display: flex;
  flex-direction: column;
  .choice-panel {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    border: 1px solid $gray-300;
    border-radius: $border-radius;
  }

  .choice-panel + .choice-panel {
    margin-top: 1rem;
    margin-left: 0;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;

    .choice-panel + .choice-panel {
      margin-top: 0;
      margin-left: 2rem;
    }
  }
}

.choice-header {
  padding: 20px 20px 0;

  @include media-breakpoint-up(md) {
    padding: 32px 32px 0;
  }

  h3 {
    margin-bottom: .25rem;
  }

  p {
    margin-bottom: 0;
  }
}

.choice-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 20px;
  margin-top: 12px;
  margin-bottom: 20px;

  @include media-breakpoint-up(md) {
    padding: 0 2rem;
    margin-bottom: 32px;
  }

  label {
    margin-bottom: 0;
    font-size: $font-size-sm;
  }

  p {
    font-size: $font-size-sm;
  }
}

.choice-footer {
  padding: 20px;
  @include media-breakpoint-up(md) {
    padding: 2rem;
  }
}

.ribbon {
  position: absolute;
  top: 25px;
  right: -25px;
  width: 120px;
  font-family: $font-family-sofia-pro;
  color: $white;
  text-align: center;
  background-color: $warning;
  transform: rotate(45deg);
}

.ribbon-wrapper {
  position: absolute;
  top: -1px;
  right: -1px;
  width: 100px;
  height: 100px;
  overflow: hidden;
}

.sticky-button-panel {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 6;
  width: 100%;
  padding: 8px 16px;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, .08), 0 0 14px 0 rgba(0, 0, 0, .16);
}
