$croppie-slider-wrap-height: 59px !default;

.photo-select {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
  font-size: 1rem;
  font-weight: $font-weight-bold;
  color: $text-color;
  cursor: pointer;
  background-color: $gray-400;

  button {
    pointer-events: none;
    background-color: transparent;
    border: 0;
  }
}

.photo-edit {
  display: flex;
  align-items: flex-end;
  width: 120px;
  height: 120px;
  background-color: $gray-400;

  label {
    width: 100%;
    padding: 5px;
    margin: 0;
    color: $white;
    background-color: $semi-transparent-background;
  }
}

svg {
  position: relative;
}

.carousel {
  .delete-button {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 30px;
    height: 30px;
    padding: 3px 5px;
    cursor: pointer;
    background-color: $white;
    border: 1px solid $gray-400;
    border-radius: 30px;

    svg {
      color: $primary;
    }
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus,
.carousel-control-prev,
.carousel-control-next {
  opacity: 1;
}

.carousel-caption {
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem 1.25rem;
  background-color: $semi-transparent-background;
}

.date-picker-icon {
  padding: .25rem 0 0 .25rem;
  border: 1px solid $gray-400;
  border-left: none;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.icon-md {
  float: left;
  width: 20px;
  margin-right: .5rem;
}

.image-container {
  max-width: 100%;
  max-height: 168px;
}

.btn-select-photo {
  width: 100%;
  height: 340px;
  border: 0;
}

.selected-photo {
  max-width: 100%;
  max-height: 430px;
}

.winback-benefit {
  width: 74px;
  height: 74px;
}

.logo-container {
  @extend .d-flex;
  @extend .justify-content-around;
  position: relative;
  width: 250px;
  height: 120px;
  overflow: hidden;
  border: solid 1px $gray-300;
}

.companyprofile-logo-container {
  @extend .d-flex;
  @extend .justify-content-around;
  position: relative;
  height: 168px;
  overflow: hidden;
  border: solid 1px $gray-300;
}

.logo-container-small {
  width: 70px;
  height: 48px;
  padding: 2px;
  background-color: $gray-100;
  border: solid 1px $gray-300;

  .image-container {
    max-width: 100%;
    max-height: 100%;
  }
}

.arrow-light > * {
  color: $accent-lighter;
}

[class*="icon-zoom-"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.icon-zoom-out {
  background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 48.1 (47250) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3ECB9D919C-952C-4EA9-8B74-FEA591DC78DA%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Icons-/-zoom-out' fill='%238CA5B5' fill-rule='nonzero'%3E%3Cpath d='M10,17.5 C14.1421356,17.5 17.5,14.1421356 17.5,10 C17.5,5.85786438 14.1421356,2.5 10,2.5 C5.85786438,2.5 2.5,5.85786438 2.5,10 C2.5,14.1421356 5.85786438,17.5 10,17.5 Z M10,19 C5.02943725,19 1,14.9705627 1,10 C1,5.02943725 5.02943725,1 10,1 C14.9705627,1 19,5.02943725 19,10 C19,14.9705627 14.9705627,19 10,19 Z M5.75,9.25 L14.25,9.25 C14.6642136,9.25 15,9.58578644 15,10 L15,10 C15,10.4142136 14.6642136,10.75 14.25,10.75 L5.75,10.75 C5.33578644,10.75 5,10.4142136 5,10 L5,10 C5,9.58578644 5.33578644,9.25 5.75,9.25 Z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E"), "#", "%23");
}

.icon-zoom-in {
  background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 48.1 (47250) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3EFF6880C1-6466-408A-B289-36DC533B16EC%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Icons-/-zoom-out' fill='%238CA5B5' fill-rule='nonzero'%3E%3Cpath d='M10,17.5 C14.1421356,17.5 17.5,14.1421356 17.5,10 C17.5,5.85786438 14.1421356,2.5 10,2.5 C5.85786438,2.5 2.5,5.85786438 2.5,10 C2.5,14.1421356 5.85786438,17.5 10,17.5 Z M10,19 C5.02943725,19 1,14.9705627 1,10 C1,5.02943725 5.02943725,1 10,1 C14.9705627,1 19,5.02943725 19,10 C19,14.9705627 14.9705627,19 10,19 Z M5.75,9.25 L14.25,9.25 C14.6642136,9.25 15,9.58578644 15,10 L15,10 C15,10.4142136 14.6642136,10.75 14.25,10.75 L5.75,10.75 C5.33578644,10.75 5,10.4142136 5,10 L5,10 C5,9.58578644 5.33578644,9.25 5.75,9.25 Z M10.75,5.75 L10.75,14.25 C10.75,14.6642136 10.4142136,15 10,15 L10,15 C9.58578644,15 9.25,14.6642136 9.25,14.25 L9.25,5.75 C9.25,5.33578644 9.58578644,5 10,5 L10,5 C10.4142136,5 10.75,5.33578644 10.75,5.75 Z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E"), "#", "%23");
}

.cr-slider-wrap {
  height: $croppie-slider-wrap-height;
  background-color: $gray-300;

  .cr-slider {
    width: 70%;
    &::-webkit-slider-thumb {
      background-color: $primary;
    }

    &::-webkit-slider-runnable-track {
      background: $gray-400;
    }
  }

  // stylelint-disable-next-line scss/selector-no-redundant-nesting-selector
  & > * {
    margin: auto 5px;
  }
}

.croppie-container {
  margin: 20px 0;

  .cr-viewport {
    border-color: $gray-800;
    box-shadow: 0 0 2000px 2000px rgba($gray-800, .5);
  }
}

.pencil {
  border-bottom: 2px solid transparent;
  fill: #5f3;
  &:hover {
    cursor: pointer;
    border-bottom: 2px solid currentColor;
  }
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.close-x {
  margin-right: -38px;
}

.logo-text {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  color: $white;
  background-color: rgba(68, 91, 105, .5);
}

.svg-container {
  svg {
    width: 30px;
    height: 40px;
  }
}


// stylelint-disable
.payment-icon-mastercard { width: 40px; height: 24px; color: transparent; background-position: 0 0 !important; }
.payment-icon-paypal { width: 76px; height: 24px; color: transparent; background-position: -160px 0 !important; }
.payment-icon-secure { width: 14px; height: 18px; margin-bottom: -4px; color: transparent; background-position: -286px 0 !important; }
.payment-icon-visa { width: 65px; height: 24px; color: transparent; background-position: -72px 0 !important; }
.payment-icon-amex { width: 38px; height: 38px; color: transparent; background-position: -58px -26px !important; }
.payment-icon-directdebit_uk { width: 69px; height: 24px; color: transparent; background-position: -98px -26px !important; }
.payment-icon-directdebit_sepa { width: 70px; height: 24px; color: transparent; background-position: -175px -29px !important; }
.payment-icon-adyen_credit_card { width: 34px;height: 24px;color: transparent;background-position: -242px 0 !important;}
.payment-icon-adyen { width: 55px;height: 18px;color: transparent;background-position: -245px -29px!important;}
