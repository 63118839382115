.market-footer {
  padding-top: 2rem;
  margin-top: 3.5rem;
  background-color: $gray-150;

  .footer-bottom-list {
    @extend .footer-bottom-list;
    align-items: normal;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .footer-nav {
    margin-bottom: 1.5rem;
  }

  hr {
    margin: 0;
  }
}
