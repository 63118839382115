.action-panel {
  .panel-link {
    padding-bottom: 0;
    font-size: 1rem;
    color: $gray-base;
    border-bottom: none;
  }
  .list-group-item .list-group-item:last-of-type {
    padding-bottom: 0;
    background-color: $accent-lighter;
  }
}
