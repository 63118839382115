.rating-label {
  display: inline-block;
  padding: 2px 10px;
  border-radius: 0;
}

.rating-box {
  align-items: center;
  justify-content: center;
  min-width: 44px;
  height: 28px;
  text-align: center;
}

.company-rating-unknown {
  &.rating-box {
    background-color: $rating-unknown;
  }
  font-weight: $font-weight-bold;
  color: $white;
}

.company-rating-very-bad,
.rating-bad {
  &.rating-box {
    background-color: $danger-light;
  }
  font-weight: $font-weight-bold;
  color: $danger;
}

.company-rating-bad,
.rating-poor {
  &.rating-box {
    background-color: $warning-light;
  }
  font-weight: $font-weight-bold;
  color: $warning;
}

.company-rating-medium,
.rating-fair,
.company-rating-good,
.rating-good,
.company-rating-very-good,
.rating-excellent {
  &.rating-box {
    background-color: $primary-light;
  }
  font-weight: $font-weight-bold;
  color: $primary;
}
