@function get-arrow-dropdown-icon-base($direction) {
  $arrow-dropdown-icon-bottom: str-replace(url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='[[fillColor]]' fill-rule='nonzero' d='M10.716 3.645a.455.455 0 0 1 .568.71l-5 4a.455.455 0 0 1-.568 0l-5-4a.455.455 0 0 1 .568-.71L6 7.418l4.716-3.773z'/%3E%3C/svg%3E%0A"), "#", "%23");
  $arrow-dropdown-icon-right: str-replace(url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='[[fillColor]]' fill-rule='nonzero' d='M3.645 1.284a.455.455 0 0 1 .71-.568l4 5a.455.455 0 0 1 0 .568l-4 5a.455.455 0 0 1-.71-.568L7.418 6 3.645 1.284z'/%3E%3C/svg%3E%0A"), "#", "%23");

  @if ($direction == bottom or $direction == top) {
    @return $arrow-dropdown-icon-bottom;
  } @else {
    @return $arrow-dropdown-icon-right;
  }
}

@function get-arrow-dropdown-icon($fillColor, $direction) {
  $icon: get-arrow-dropdown-icon-base($direction);
  @return str-replace(str-replace($icon, "[[fillColor]]", $fillColor), "#", "%23");
}

@mixin arrow($position, $color: $gray-800, $direction: bottom) {
  @if $direction == left or $direction == top {
    @include arrow-rotate($position, 180deg);
  }@else {
    @include arrow-rotate($position, 0deg); // So it can be overruled by the next arrow
  }

  &::#{$position} {
    display: inline-block;
    content: "";
    background: get-arrow-dropdown-icon($color, $direction) no-repeat;
    border: 0;
  }

  @if $direction == right or $direction == left {
    &::#{$position} {
      width: $arrow-dropdown-size-y;
      height: $arrow-dropdown-size-x;
      vertical-align: middle;
    }
  } @else {
    &::#{$position} {
      width: $arrow-dropdown-size-x;
      height: $arrow-dropdown-size-y;
      vertical-align: middle;
    }
  }
}

@mixin arrow-hide($position) {
  &.hide-arrow-dropdown-toggle {
    &::#{$position} {
      visibility: hidden;
    }
  }
}

@mixin arrow-transition($position) {
  &.arrow-dropdown-transition {
    &::#{$position} {
      transition-duration: .1s;
    }
  }
}

@mixin arrow-rotate($position, $rotation) {
  &::#{$position} {
    transform: rotate($rotation);
  }
}

@mixin arrow-dropdown($position) {
  @include arrow($position);
  @include arrow-hide($position);
  @include arrow-transition($position);

  @if $position == before {
    &::after {
      display: none;
    }
  }

  $rotations: 90deg, 180deg;
  @each $rotation in $rotations {
    @at-root {
      .show {
        > .arrow-dropdown-toggle-rotate-#{$rotation} {
          @include arrow-rotate($position, $rotation);
        }
      }
    }
  }

  $directions: right, bottom, left;
  $styles: (
    primary: $primary,
    accent: $accent
  );
  @each $direction in $directions {
    &.arrow-dropdown-toggle-#{$direction} {
      @each $style, $color in $styles {
        &.arrow-dropdown-toggle-#{$style} {
          @include arrow($position, $color, $direction);
        }
      }
    }
  }
}

