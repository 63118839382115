$loader-width: 2.5rem !default;
$loader-height: $loader-width !default;
$iframe-loading-min-height: 400px !default;
$btn-loading-margin-left: .5rem !default;

.loader {
  width: $loader-width;
  height: $loader-height;
  background-image: $custom-icon-loading;
}

.iframe-loading {
  height: 100%;
  min-height: $iframe-loading-min-height;
  background: $custom-icon-loading center center no-repeat;
  background-size: $loader-width $loader-height;
}

.btn-loading {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &::after {
    $btn-loading-icon-side-size: calc(#{$font-size-base} * #{$btn-line-height});

    display: block;
    width: $btn-loading-icon-side-size;
    height: $btn-loading-icon-side-size;
    margin-left: $btn-loading-margin-left;
    content: "";
    background: $custom-btn-loading-icon no-repeat;
  }
}

