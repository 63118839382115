$yoast-breadcrumb-icon-right: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Cpath fill='#{$gray-600}' fill-rule='evenodd' d='M4.62 2.825a.5.5 0 0 1 .76-.65l3 3.5a.5.5 0 0 1 0 .65l-3 3.5a.5.5 0 1 1-.76-.65L7.341 6l-2.72-3.175z'/%3E%3C/svg%3E"), "#", "%23") !default;
$yoast-breadcrumb-icon-width: 12px !default;
$yoast-breadcrumb-icon-height: $yoast-breadcrumb-icon-width !default;
$yoast-breadcrumb-font-family: $font-family-sofia-pro !default;
$yoast-breadcrumb-separator-margin-x: 6px !default;
$yoast-breadcrumb-margin-top: 21px !default;
$yoast-breadcrumb-margin-bottom: 30px !default;
$yoast-breadcrumb-margin-bottom-desktop: 46px !default;

/* stylelint-disable */
.yoast-breadcrumb {
  margin-top: $yoast-breadcrumb-margin-top;
  margin-bottom: $yoast-breadcrumb-margin-bottom;
  font-family: $yoast-breadcrumb-font-family;
  font-size: $font-size-sm;
  color: $gray-600;

  @include media-breakpoint-up(md) {
    margin-bottom: $yoast-breadcrumb-margin-bottom-desktop;
  }

  a {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    font-family: $yoast-breadcrumb-font-family;
    color: $gray-600;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .breadcrumb-separator ~ span a {
    margin-right: $yoast-breadcrumb-separator-margin-x;
    content: "";
  }

  .breadcrumb-separator ~ .breadcrumb_last {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .breadcrumb-separator ~ span a::before,
  .breadcrumb-separator ~ .breadcrumb_last::before {
    display: inline-block;
    width: $yoast-breadcrumb-icon-width;
    height: $yoast-breadcrumb-icon-height;
    margin-right: $yoast-breadcrumb-separator-margin-x;
    margin-bottom: 3px; // tightly coupled to the height of the text, otherwise it doesn't align
    vertical-align: middle;
    content: "";
    background: $yoast-breadcrumb-icon-right;
    border: none;
  }

  .breadcrumb_last > * {
    white-space: normal;
  }
}
/* stylelint-enable */
