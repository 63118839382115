// stylelint-disable selector-max-compound-selectors, selector-max-type

.entry-content {
  word-wrap: break-word;

  h1 {
    margin-top: 2rem;
    margin-bottom: .5rem;
    @include media-breakpoint-up(lg) {
      margin-top: 3.5rem;
    }
  }

  h2 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    @include media-breakpoint-up(lg) {
      margin-top: 3.5rem;
    }
  }

  h3 {
    margin-top: 1.5rem;
    margin-bottom: .75rem;
    @include media-breakpoint-up(lg) {
      margin-top: 2.5rem;
    }
  }

  h4 {
    margin-top: 1.5rem;
  }

  h5,
  h6 {
    margin-top: 1rem;
  }

  p {
    margin-bottom: 1.5rem;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .article-table {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: $font-size-sm;
    border: solid 1px $gray-300;
    border-radius: .25rem;

    table {
      width: 100%;
      margin-bottom: 0;
      th {
        padding: 8px 10px 12px;
        color: $gray-700;
        background-color: $gray-300;
      }

      tbody tr:not(:last-child){
        border-bottom: 1px solid $gray-300;
      }

      td {
        padding: 12px 10px;
        color: $gray-800;
        background-color: $white;
      }

      @include media-breakpoint-up(lg) {
        td,
        th {
          padding-right: 20px;
          padding-left: 20px;
        }
      }
    }
  }

  // The first p should be large
  > p:first-child {
    @extend .p-large;
  }

  // The first heading should not contain margin top
  @for $index from 1 through 6 {
    > h#{$index}:first-child {
      margin-top: 0;
    }
  }
}
