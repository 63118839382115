// stylelint-disable declaration-no-important

//
// Headings
//

h1,
.h1 {
  margin-bottom: 2rem;
  line-height: $h1-line-height;

  @include media-breakpoint-up(md) {
    margin-bottom: 3.5rem;
    font-size: $h1-font-size-desktop;
    line-height: $h1-line-height-desktop;
  }
}

h2,
.h2 {
  margin-bottom: 1.5rem;
  line-height: $h2-line-height;

  @include media-breakpoint-up(md) {
    margin-bottom: 2rem;
    font-size: $h2-font-size-desktop;
    line-height: $h2-line-height-desktop;
  }
}

h3,
.h3 {
  margin-bottom: .5rem;
  line-height: $h3-line-height;

  @include media-breakpoint-up(md) {
    margin-bottom: 1rem;
    font-size: $h3-font-size-desktop;
    line-height: $h3-line-height-desktop;
  }
}

h4,
.h4 {
  margin-bottom: .5rem;
  line-height: $h4-line-height;

  @include media-breakpoint-up(md) {
    margin-bottom: .5rem;
    font-size: $h4-font-size-desktop;
    line-height: $h4-line-height-desktop;
  }
}

h5,
.h5 {
  margin-bottom: 0;
  line-height: $h5-line-height;
  @include media-breakpoint-up(md) {
    font-size: $h5-font-size-desktop;
    line-height: $h5-line-height-desktop;
  }
}

h6,
.h6 {
  margin-bottom: 0;
  line-height: $h6-line-height;

  @include media-breakpoint-up(md) {
    font-size: $h6-font-size-desktop;
    line-height: $h6-line-height-desktop;
  }
}

//
// Links
//

a {
  font-family: $link-font-family;
  cursor: pointer;

  &:active {
    font-weight: $font-weight-bold;
  }
}

p > a {
  font-family: $link-font-family;
}

// Copied the a styles as if we extend it, there is an overwriting rule for no href or tabindex links and it removes some styles
.link-secondary {
  font-family: $link-font-family;
  color: $link-secondary-color;
  text-decoration: $link-decoration;
  cursor: pointer;
  background-color: transparent; // Remove the gray background on active links in IE 10.
  // stylelint-disable-next-line property-no-vendor-prefix
  -webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.

  &:hover {
    color: $link-secondary-color;
    text-decoration: $link-hover-decoration;
  }

  &:active {
    font-weight: $font-weight-bold;
  }
}

// Copied the a styles as if we extend it, there is an overwriting rule for no href or tabindex links and it removes some styles
.link-accent {
  font-family: $link-font-family;
  color: $link-accent-color;
  text-decoration: $link-decoration;
  cursor: pointer;
  background-color: transparent; // Remove the gray background on active links in IE 10.
  // stylelint-disable-next-line property-no-vendor-prefix
  -webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.

  &:hover {
    color: $link-accent-color;
    text-decoration: $link-hover-decoration;
  }

  &:active {
    font-weight: $font-weight-bold;
  }
}

//
// Paragraphs
//

p {
  font-size: $font-size-base;
  line-height: $line-height-base;
}

.p-small {
  font-size: $font-size-sm;
  line-height: $line-height-sm;
}

.p-large {
  font-size: $font-size-base;
  line-height: $line-height-base;

  @include media-breakpoint-up(md) {
    font-size: $font-size-lg;
    line-height: $line-height-lg;
  }
}

.smaller {
  font-size: $font-size-sm;
  line-height: 1.25rem;
}

.font-size-md {
  font-size: $font-size-base !important;
}

.font-size-lg {
  font-size: $font-size-lg;
}

.font-size-xs {
  font-size: $font-size-xs;
}

.lead {
  font-size: $font-size-lg !important;
  line-height: 1.625rem !important;
}

.text-lg {
  font-size: 2.875rem;
}

.text-gray-dark {
  color: $gray-800 !important;
}

.text-gray {
  color: $gray-700 !important;
}

.text-gray-light {
  color: $gray-600;
}

.text-gray-lighter {
  color: $gray-500;
}

.text-empty-state {
  font-style: italic;
  color: $gray-500;
}

.text-sm-center {
  @media (max-width: 768px) {
    text-align: center !important;
  }
}

.label {
  font-weight: $font-weight-bold;
  color: $gray-700;
}

.block-link {
  display: block;
  padding: 16px 0;
  font-family: $font-family-base;
  color: $text-color;

  h3 {
    margin-bottom: 12px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 20px 0;
  }
}

.block-link:hover {
  padding: 16px 15px;
  margin: 0 -15px;
  color: $text-color;
  text-decoration: none;
  background-color: $gray-200;

  @include media-breakpoint-up(lg) {
    padding: 20px 15px;
  }
}

// Because of a strange bug in the font where some digits are 1px smaller than the others we make the phonenumber 26px instead of the 24 from the design
.phone-number-accent {
  font-size: 26px;
  font-weight: $font-weight-bold;
  color: $accent;
}

.font-sofia-pro {
  font-family: $font-family-sofia-pro;
}

.font-proxima-nova {
  font-family: $font-family-proxima-nova;
}

.text-strikethrough {
  text-decoration: line-through;
}

.word-break {
  word-break: break-word;
}
