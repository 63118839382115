.btn {
  font-family: $btn-font-family;
  box-shadow: 0 -2px 0 0 rgba(0, 0, 0, .1) inset;

  &[class*="btn-outline-"],
  &.btn-secondary,
  &.btn-link {
    box-shadow: none;
  }

  &.btn-link {
    padding: 0;
    text-decoration: underline;

    &[class*="arrow-"]::before {
      margin-right: $btn-link-before-arrow-margin;
    }
  }
}

.btn-padding {
  padding: $btn-padding-y 1rem;
}

.btn-icon {
  padding: $btn-padding-y 1rem;
  background: transparent;
  border: 0;
}

.btn-no-outline {
  font-weight: $font-weight-bold;
  color: $gray-700;
  background: none;
  border: 0;
  box-shadow: none;

  &:hover {
    color: $gray-800;
  }

  &.disabled {
    color: $gray-500;
  }

  svg {
    margin-bottom: -2px;
  }
}

.btn-primary {
  @include button-variant($primary, $primary, $primary-dark, $primary-dark, $primary-dark, $primary-dark);

  border: 0;

  &.btn {
    padding: $btn-padding-y $btn-padding-x;

    &.btn-sm {
      padding: $btn-padding-y-sm + .1rem $btn-padding-x-sm + .1rem;
    }

    &.btn-lg {
      padding: $btn-padding-y-lg $btn-padding-x-lg;
    }
  }
}

.btn-secondary {
  @include button-variant(theme-color("secondary"), $gray-400, theme-color("secondary"), $gray-500, theme-color("secondary"), $gray-400);
  color: $primary;
  background-color: transparent;
  border-width: 1.5px;

  &:hover {
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active {
    background-color: transparent;
  }

  &:disabled {
    color: $primary;
  }

  &:not(:disabled) {
    @include hover() {
      color: $primary-dark;
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $primary-dark;

    &:focus {
      color: $primary-dark;
    }
  }
}

.btn-transparent {
  @include button-variant(transparent, $gray-500, transparent, $gray-600, transparent, $gray-500);
  color: $primary;
  border-width: 1.5px;
  box-shadow: none;

  &:not(:disabled) {
    @include hover() {
      color: $primary-dark;
    }
  }

  &:disabled {
    color: $primary-light;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $primary-dark;

    &:focus {
      color: $primary-dark;
    }
  }
}

.btn-input-group {
  @include button-variant($white, $input-border-color, $gray-200, $gray-500, $white, $gray-400);
  display: flex;
  padding-right: .5rem;
  padding-left: .5rem;
  box-shadow: none;
}

.clickable:hover {
  cursor: pointer;

  .card-header {
    color: $gray-800;
  }
}

button:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn-high-contrast {
  color: $gray-800;
  background-color: $white;
  border: 1px solid $gray-400;
  box-shadow: none;
}

.card-footer {
  .btn {
    &:first-child {
      margin-left: 0;
    }
    margin-left: .5rem;
  }
}


.btn-sm-scalable {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);

  @include media-breakpoint-up(lg) {
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
  }
}

.btn-arrow-tail {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &::after {
    display: block;
    width: 24px;
    height: 24px;
    margin-left: 8px;
    content: "";
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 59.1 (86144) - https://sketch.com --%3E%3Ctitle%3Ebutton-arrow-tail%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='button-arrow-tail-copy' transform='translate(-107.000000, -14.000000)'%3E%3Cg id='Group-2' transform='translate(77.000000, 8.000000)'%3E%3Cg id='Group' transform='translate(30.000000, 4.000000)'%3E%3Crect id='Rectangle' x='0' y='0' width='16' height='20'%3E%3C/rect%3E%3Cpath d='M8,4 L14,10 L8,16 M0,10 L14,10' id='Path-2' stroke='%23FFFFFF' stroke-width='3'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E ");
    background-position: center;
    background-repeat: no-repeat;
  }
}

.btn-wizard-wrap {
  &.sd-mt-4 {
    margin-top: 24px !important;
  }
  &.sd-mt-2 {
    margin-top: 16px !important;
  }
  .free {
    font-size: 14px;
    margin-top: 2px;
    .glyphicon-ok {
      display: inline-block;
      margin-bottom: -3px;
      width: 14px;
      height: 14px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%231abd5e' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 9.5L7.5 15 18 4.5'/%3E%3C/svg%3E%0A");
    }
  }
}
