$nav-link-margin-x: .7rem !default;

.navbar {
  z-index: $zindex-navbar;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  font-size: $font-size-nav;
  font-weight: $font-weight-normal;
  background-color: $white;
  box-shadow: $all-border-shadow;

  // stylelint-disable declaration-no-important
  // The bootstrap collapsing JS adds a height to the collapsable which is wrong when a div only is collapsable on mobile
  @include media-breakpoint-up(lg) {
    .navbar-collapse.collapsing {
      height: auto !important;
      transition: none;
    }
  }

  .dropdown-toggle-custom::after {
    top: 1.8rem;

    @include media-breakpoint-down(md) {
      top: 1rem;
    }
  }

  @include media-breakpoint-up(lg) {
    .navbar-nav .dropdown-menu-right {
      right: $nav-link-margin-x;
    }

    .last + .dropdown-menu-right {
      right: 0;
    }
  }

  .dropdown-menu {
    top: 3.5rem;
  }

  .navbar-brand {
    @extend .d-flex;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: $primary;

    svg {
      max-width: 15rem;
      height: 2.25rem;
    }
  }

  .navbar-brand:hover {
    color: $primary-dark;
  }

  .divider {
    margin: 1.2rem;
    border-left: 1px solid $gray-400;
  }

  .dropdown-item {
    font-size: $font-size-sm;
    color: $gray-800;
  }

  @include media-breakpoint-down(md) {
    padding-left: 1rem;

    .navbar-nav {
      margin-bottom: 1.5rem;
    }

    .divider {
      width: 100%;
      height: 0;
      margin: 0;
      border-top: 1px solid $gray-400;
      border-left: none;
    }

    .dropdown-menu {
      box-shadow: none;
    }

    .dropdown-item {
      color: $gray-700;
      box-shadow: none;
    }
  }

  &.navbar-transparant {
    background: transparent;
  }
}

@include media-breakpoint-up(lg) {
  .modal-open {
    padding-right: $default-scrollbar-width;
  }

  .modal-open .absolute-menu > div {
    padding-right: $default-scrollbar-width;
  }
}

.absolute-submenu {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  padding-top: $navbar-height;
  overflow: auto;
  background-color: $white;

  @include media-breakpoint-up(lg) {
    padding-top: $navbar-height-lg;
  }
}

.navbar-light {
  .nav-item:hover .nav-link {
    box-shadow: inset 0 -4px 0 0 $gray-400;
  }

  .nav-item.active .nav-link {
    box-shadow: inset 0 -4px 0 0 $primary;
  }
}

.navbar-toggler {
  display: flex;
  align-items: center;
  padding: 0;
  font-size: $font-size-base;
  border: 0;

  .navbar-toggler-icon {
    width: $navbar-light-toggler-icon-width;
    height: $navbar-light-toggler-icon-height;
    background-image: $navbar-light-toggler-icon-bg;
  }
}

.navbar-height {
  height: $navbar-height;
  @include media-breakpoint-up(lg) {
    height: $navbar-height-lg;
  }
}

.navbar-nav {
  [class="nav-item"]:first-of-type {
    margin-left: 0;
  }

  .nav-item .nav-link {
    padding: 1.7rem .3rem;
    margin: 0 .7rem;
    font-weight: $font-weight-normal;
    cursor: pointer;

    &.last {
      margin-right: 0;
    }
  }

  .nav-item .nav-link-small {
    padding-top: 1rem;
  }

  @include media-breakpoint-down(md) {
    .nav-item:hover .nav-link,
    .nav-item.active .nav-link {
      box-shadow: none;
    }
    .nav-item .nav-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 0;
      margin: 0;
    }
  }
}

.account-switcher {
  min-width: 16rem;
  @include media-breakpoint-down(md) {
    min-width: 100%;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 1rem;
    padding-left: 1rem;
    text-align: left;
  }

  .dropdown-item {
    padding: 0 1.25rem;
  }
}

.dropdown-menu {
  min-width: 100%;
  padding: 0;
  margin-top: -2px;
}

.dropdown-item {
  border: 0;
  box-shadow: $box-shadow-bottom;

  .card {
    padding: .875rem 0 1.25rem;
    margin: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }

  .card-header {
    padding: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }

  .card-body {
    padding: 0;
    background-color: transparent;
  }

  a {
    color: $gray-800;
    text-decoration: none;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  color: $gray-800;
  background-color: $gray-200;
}

.second-navbar {
  padding: 10px 0;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .08);
}

@function get-search-icon($color) {
  $search-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='[[fillColor]]' fill-rule='nonzero' d='M16 15.292l5.354 5.354a.5.5 0 0 1-.708.708l-5.354-5.355a8 8 0 1 1 .707-.707zM10 17a7 7 0 1 0 0-14 7 7 0 0 0 0 14z'/%3E%3C/svg%3E");
  @return str-replace(str-replace($search-icon, "[[fillColor]]", $color), "#", "%23");
}

.search-icon {
  width: 24px;
  height: 24px;
  background: get-search-icon($text-color) no-repeat;

  &:hover {
    background: get-search-icon($primary) no-repeat;
  }
  &:active {
    background: get-search-icon($primary-dark) no-repeat;
  }
}

.absolute-menu {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-absolute-menu;
  width: 100%;
  max-height: 100vh;
  overflow: auto;

  @include media-breakpoint-up(lg) {
    overflow: visible;
    background-color: transparent;
  }

  .header-submenu {
    width: 100%;
    overflow: hidden;

    .header-submenu-margins {
      margin-top: 1rem;
      margin-bottom: 2rem;

      @include media-breakpoint-up(lg) {
        margin-top: 3rem;
        margin-bottom: 4.5rem;
      }
    }
  }

  .nav-link:not(.btn) {
    font-family: $nav-link-font-family;
    color: $text-color;
    //stylelint-disable property-no-vendor-prefix
    //needed to make the menu button look normal
    -webkit-appearance: none;
    //stylelint-enable property-no-vendor-prefix
  }

  .nav-link:not(.btn):hover {
    color: $primary;
  }

  .nav-link:not(.btn):active {
    color: $primary-dark;
  }
}

.white-header {
  z-index: 800;
  height: $navbar-height;
  background: $white;
  box-shadow: $all-border-shadow;

  @include media-breakpoint-up(lg) {
    height: $navbar-height-lg;
  }
}
