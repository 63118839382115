.step-navigation {
  margin-top: .5rem;
  font-family: $btn-font-family;
  font-size: $font-size-sm;
}

.check-small {
  display: inline-block;
  width: $checkmark-width;
  height: $checkmark-height;
  font-family: $font-family-sofia-pro;
  background-image: $custom-icon-valid;
}

