.table-striped {
  tbody {
    tr:nth-of-type(even) {
      background-color: $table-no-accent-bg;
    }
  }
}

.card-body > .table {
  border-bottom-right-radius: $card-border-radius;
  border-bottom-left-radius: $card-border-radius;
}

.table {
  margin-bottom: 0;
  overflow: hidden;
  color: $text-color;

  tbody > .disabled {
    background-color: $gray-200;
    opacity: .5;
  }

  th {
    padding: .5rem 1rem;
    font-family: $font-family-sofia-pro;
    font-size: $font-size-sm;
  }

  td {
    height: 3.325rem;
    padding: .5rem 1rem;
    font-size: $font-size-sm;
    vertical-align: middle;
  }

  .thead-light {
    th {
      color: $table-head-color;
      background-color: $table-head-bg;
      border: 0;
    }
  }

  .no-rows {
    td {
      padding: 7rem 1rem;
      font-size: $font-size-base;
      color: $gray-600;
      text-align: center;
      background-color: $gray-200;
    }
  }

  &.hoverable tr {
    &:hover {
      background-color: $gray-200;
    }
  }
}

.table-dark {
  th,
  td,
  thead th {
    color: $table-dark-color;
  }
}

.table-card {
  .card-body {
    padding: 0;
  }
}

%flex-table-row {
  padding-top: .875rem;
  padding-bottom: .875rem;
  color: $table-head-color;
  vertical-align: top;

  &.shadow-bottom {
    box-shadow: 0 1px $table-border-color;
  }
  &.shadow-top {
    box-shadow: 0 -1px 1px $table-border-color;
  }

  &.shadow-lg-none {
    @include media-breakpoint-up(lg) {
      box-shadow: none;
    }
  }
}

.flex-table-header {
  @extend %flex-table-row;
  padding: .5rem 0;
  font-weight: $font-weight-bold;
  color: $gray-700;
  background-color: $table-head-bg;
}

.flex-table-data {
  @extend %flex-table-row;
  @extend .border-bottom;
}
