.lead-step-progress-bar {
  display: flex;
  align-items: center;
  margin: 1.25rem 0;
  font-family: $font-family-sofia-pro;

  @include media-breakpoint-up(lg) {
    margin: 1.5rem 0;
  }

  .step {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $gray-500;

    @include media-breakpoint-up(lg) {
      font-size: 16px;
    }

    .step-label {
      line-height: 1;
      // Hide all inactive step labels on very small screens displays to make it fit
      @media (max-width: 320px) {
        display: none;
      }
    }

    .step-circle {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: $circle-b-diameter;
      height: $circle-b-diameter;
      background-color: $gray-300;

      @include media-breakpoint-up(lg) {
        width: $circle-b-diameter-desktop;
        height: $circle-b-diameter-desktop;
      }
    }

    &.completed,
    &.active {
      font-weight: $font-weight-bold;
    }

    &.completed {
      color: $accent;
      cursor: pointer;

      .step-circle {
        background-color: $accent-lighter;
      }
    }

    &.active {
      color: $gray-800;

      .step-circle {
        color: $white;
        background-color: $accent;
      }

      .step-label {
        display: block;
      }
    }
  }
}

.progress-bar-icons {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 16px;

  .bar-line {
    flex-grow: 1;
    width: 8.33%;
    height: 2px;

    &:first-child {
      flex-grow: 0;
      width: 16px;

      &::before {
        position: absolute;
        left: -16px;
        width: 16px;
        height: 2px;
        content: "";
        background: inherit;

        @include media-breakpoint-up(lg) {
          left: -32px;
          width: 32px;
        }
      }
    }
  }

  .bar-icon {
    width: 8px;
    height: 8px;
    border-radius: 4px;

    &.active {
      width: 24px;
      height: 24px;
      border-radius: 12px;

      svg {
        width: 100%;
        height: 100%;
        vertical-align: inherit;
      }
    }
  }

  .bar-complete {
    background-color: $accent;
  }

  .bar-success {
    background-color: $primary;
  }

  .bar-error {
    background-color: $danger;
  }

  .bar-todo {
    background-color: $gray-300;
  }
}

.progress-bar-speech-bubble {
  position: relative;
  padding: 12px 16px;

  @include media-breakpoint-up(lg) {
    padding: 32px;
  }

  margin-top: 1rem;
  border: 1px solid $gray-300;
  border-radius: 4px;

  &::after {
    position: absolute;
    top: -9px;
    left: 18px;
    width: 16px;
    height: 16px;
    content: "";
    background-color: $white;
    border: 1px solid $gray-300;
    border-right: 0;
    border-bottom: 0;
    transform: rotate(45deg);
  }

  &.center::after {
    left: calc(50% - 8px);
  }

  &.none::after {
    content: none;
  }

  &.right::after {
    right: 20px;
    left: auto;
  }

  p:last-child {
    margin-bottom: 0;
  }
}
