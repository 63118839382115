.footer-bottom-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .5rem 0 1.5rem;

  .nav {
    display: inline-flex;
  }

  a {
    color: $gray-600;
  }

  li:first-child .separator {
    display: none;
  }

  .brand {
    margin-top: .5rem;
    margin-right: 0;
    margin-bottom: 1rem;
    font-family: $nav-link-font-family;
    color: $gray-500;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      margin-right: 1.25rem;
      margin-bottom: 0;
    }
  }

  .logo {
    margin-bottom: 1rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      margin-left: auto;
    }
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
}

.footer-nav {
  flex-direction: column;
  margin-bottom: 2rem;

  li {
    margin-bottom: .5rem;
  }

  a {
    color: $gray-600;
  }
}
