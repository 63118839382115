@font-face {
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/proxima-nova/ProximaNova-RegularWeb.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/proxima-nova/ProximaNova-BoldWeb.woff") format("woff");
  font-display: swap;
}

/**
 * @license
 * MyFonts Webfont Build ID 3617159, 2018-08-01T03:22:41-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: SofiaPro-Bold by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/bold/
 *
 * Webfont: SofiaProMedium by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/medium/
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3617159
 * Licensed pageviews: 180,000
 * Webfonts copyright: Copyright &#x00A9; Olivier Gourvat - Mostardesign Type Foundry, 2016. All rights reserved.
 *
 * © 2018 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
//@import url("//hello.myfonts.net/count/373187");

@font-face {
  font-family: SofiaPro;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/sofia-pro/373187_0_0.eot");
  src: url("../fonts/sofia-pro/373187_0_0.eot?#iefix") format("embedded-opentype"), url("../fonts/sofia-pro/373187_0_0.woff2") format("woff2"), url("../fonts/sofia-pro/373187_0_0.woff") format("woff"), url("../fonts/sofia-pro/373187_0_0.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: SofiaPro;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/sofia-pro/373187_1_0.eot");
  src: url("../fonts/sofia-pro/373187_1_0.eot?#iefix") format("embedded-opentype"), url("../fonts/sofia-pro/373187_1_0.woff2") format("woff2"), url("../fonts/sofia-pro/373187_1_0.woff") format("woff"), url("../fonts/sofia-pro/373187_1_0.ttf") format("truetype");
  font-display: swap;
}