.jumbotron {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin-top: 1.5rem;
  margin-bottom: .5rem;

  .body {
    flex: 1 1 auto;
    flex-grow: 100;
    margin-bottom: .5rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  .button-group {
    padding-left: 1rem;
  }

  .title {
    flex: 2 100%;
    font-size: 1rem;
    font-weight: $font-weight-bold;
  }

  &.success {
    background-color: $primary-light;
  }

  &.warning {
    background-color: $warning-light;
  }

  &.primary {
    background-color: $accent-lighter;
    a {
      color: $accent;
    }
  }

  &.danger {
    background-color: $danger-light;
  }
}

@include media-breakpoint-down(sm) {
  .jumbotron {
    flex-flow: column;

    .button-group {
      padding-left: 0;
    }
  }
}
