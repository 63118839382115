.expandable-input:empty::before {
  display: block; /* For Firefox */
  color: $gray-600;
  content: attr(data-placeholder);
}

.expandable-input {
  max-height: 4rem;
  overflow-y: scroll;
  word-break: break-word;
  cursor: text;
}
