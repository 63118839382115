$component-margin-y: 2rem !default;
$component-margin-y-desktop: 2rem !default;

$component-large-margin-y: 2rem !default;
$component-large-frontpage-margin-y: 4rem !default;
$component-large-margin-y-desktop: 7rem !default;

$market-component-margin-y: 3rem !default;
$market-component-margin-y-desktop: 5rem !default;

$panel-margin-y: 1rem !default;
$panel-margin-y-desktop: 2rem !default;

$cta-margin-y: 1.5rem !default;
$cta-margin-y-desktop: 2.5rem !default;

body {
  display: flex;
  flex-direction: column;
}

.page-container,
.front-page-container {
  overflow-x: hidden;
}

.b2c-page-container {
  @extend .container;
  margin-top: 1.5rem;
  @include media-breakpoint-up(lg) {
    margin-top: 2rem;
  }
}
.row.no-wrap {
  flex-wrap: nowrap;
  overflow-x: auto;

  .col,
  > [class^="col-"] {
    min-width: 300px;
  }
}

.row.row-narrow {
  @include media-breakpoint-down(lg) {
    > [class^="col-"]:first-child {
      padding-right: 4px;
    }
    > [class^="col-"]:last-child {
      padding-left: 4px;
    }
    > [class^="col-"]:not(:first-child):not(:last-child) {
      padding-right: 4px;
      padding-left: 4px;
    }
  }
}

.main {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
}

.header,
.navbar,
.footer {
  flex-shrink: 0;
}

//Component containers

.static-page-container {
  margin-top: 2rem;
  overflow-x: hidden;

  @include media-breakpoint-up(md) {
    margin-top: 3.5rem;
  }
}

.component-container {
  margin: $component-margin-y 0;

  @include media-breakpoint-up(lg) {
    margin: $component-margin-y-desktop 0;
  }
}

.component-container-large {
  margin: $component-large-margin-y 0;

  @include media-breakpoint-up(lg) {
    margin: $component-large-margin-y-desktop 0;
  }
}

.market-component-container {
  margin: $market-component-margin-y 0;

  @include media-breakpoint-up(lg) {
    margin: $market-component-margin-y-desktop 0;
  }
}

.panel-container {
  margin: $panel-margin-y 0;

  @include media-breakpoint-up(lg) {
    margin: $panel-margin-y-desktop 0;
  }
}


.call-to-action-container {
  margin: $cta-margin-y 0;

  @include media-breakpoint-up(lg) {
    margin: $cta-margin-y-desktop 0;
  }
}

.frontpage-component-container {
  margin: $component-large-frontpage-margin-y 0;

  @include media-breakpoint-up(lg) {
    margin: $component-large-margin-y-desktop 0;
  }
}

.component-container:first-child,
.component-container-large:first-child,
.panel-container:first-child,
.frontpage-component-container:first-child {
  margin-top: 0;
}

.component-container:last-child,
.component-container-large:last-child,
.panel-container:last-child,
.frontpage-component-container:last-child {
  margin-bottom: 0;
}


//End of component containers
