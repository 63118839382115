.card {
  font-size: $font-size-sm;
  border: 0;
  box-shadow: $all-border-shadow;

  @include media-breakpoint-down(sm) {
    &.outside-container {
      margin-right: -($grid-gutter-width / 2);
      margin-left: -($grid-gutter-width / 2);
      border-top: 1px solid $gray-300;
      border-bottom: 1px solid $gray-300;
      border-radius: 0;
      box-shadow: none;
    }
  }
}

.card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: $card-header-padding;
  font-size: 1rem;
  font-weight: $font-weight-bold;
  line-height: 1.63;
  color: $text-color;
  background-color: transparent;
  border: 0;
  box-shadow: $box-shadow-bottom;

  @extend .flex-wrap;

  &.select-container {
    @extend .flex-column;
    @extend .flex-sm-row;
    @extend .align-items-start;
    @extend .align-items-sm-center;
  }

  .title {
    order: -1;
    max-width: 100%;
    overflow: hidden;
    font-family: $card-title-font-family;
    color: $text-color;
  }

  .button-container ~ .title{
    max-width: 70%;
    margin-right: .5rem;
  }

  .header-dropdown {
    margin-top: .5rem;

    @include media-breakpoint-up(sm) {
      width: auto;
      margin: -.3rem 0;
    }
  }

  .header-notification {
    order: 4;
    width: 100%;
    margin: .5rem 0 0;
    font-size: $font-size-sm;

    @include media-breakpoint-up(lg) {
      order: 2;
      width: auto;
      margin: 0 .5rem 0 auto;
    }
  }

  .button-container {
    order: 3;
    margin: -.3rem 0; //prevent the panel header from growing because of the button
  }
}

.card-body {
  padding: 0;
  .container {
    padding: 17px 20px 19px;
  }

  > .list-group .list-group-item:last-child {
    border-radius: $border-radius;
  }
}

.filter-option-wrapper {
  .filter-option {
    padding: 13px 15px;
    color: $dark;
  }
}

// stylelint-disable declaration-no-important
.vertical-list .list-element:last-of-type {
  margin-bottom: 0 !important;
}

.horizontal-list-lg .list-element {
  @include media-breakpoint-up(lg) {
    margin-bottom: 0 !important;
  }
}

.horizontal-list-md .list-element {
  @include media-breakpoint-up(md) {
    margin-bottom: 0 !important;
  }
}

.list-element a {
  display: flex;
  align-items: center;
}
