.card-image-container {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  margin-right: -$grid-gutter-width / 2;
  margin-left: -$grid-gutter-width / 2;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    margin-right: 0;
    margin-left: 0;
  }

  .right-image-container {
    .image {
      width: 100%;
      @include media-breakpoint-up(md) {
        border-radius: $border-radius;
      }
    }
    @include media-breakpoint-up(md) {
      width: $image-horizontal-overlap;
      margin-top: 2.5rem;
    }
  }
}

.description-card-container {
  margin-right: $grid-gutter-width / 2;
  margin-left: $grid-gutter-width / 2;
  @include media-breakpoint-up(md) {
    z-index: $zindex-front;
    order: 0;
    width: $card-horizontal-overlap;
    margin-right: 0;
    margin-left: 0;
  }

  .description-card {
    position: relative;

    .description-card-title {
      margin-bottom: 1rem;
      @include media-breakpoint-up(md) {
        margin-bottom: 2rem;
      }
    }

    padding: 1.25rem;
    margin-top: $card-vertical-overlap;
    p {
      margin-bottom: 1rem;
    }

    @include media-breakpoint-up(md) {
      padding: 1.875rem;
      margin-top: 0;
      margin-right: -2rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 3.125rem;
    }
  }
}

.post-card-container {
  padding-right: .5rem;
  padding-left: .5rem;

  @include media-breakpoint-up(lg) {
    padding-right: 0;
    padding-left: 0;
  }
  .post-card-wrapper {
    padding: .5rem;

    @include media-breakpoint-up(lg) {
      padding: 0 $grid-gutter-width / 2;
    }

    .post-card {
      @extend .card;
      height: 100%;
      transition: transform .05s ease-in;

      .card-img-top {
        object-fit: cover;
        height: $post-image-height-mobile;
        @include media-breakpoint-up(sm) {
          height: $post-image-height;
        }
      }

      .card-body {
        height: $post-body-height-mobile;
        margin: 1.25rem;
        overflow: hidden;

        @include media-breakpoint-up(md) {
          height: auto;
        }
      }

      .card-title {
        margin-bottom: 0;
        font-weight: $font-weight-normal;
        @extend .text-gray-light;
      }

      .card-text {
        @extend .text-gray-dark;
      }

      a {
        text-decoration: none;
      }

      &:hover {
        transform: translateY(-4px);
      }
    }
  }
}
