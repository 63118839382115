.modal-bottom-left {
  bottom: 0;
  left: 0;
  @include media-breakpoint-up(md) {
    bottom: 52px;
    left: 52px;
  }

  &.panel {
    position: absolute;
  }
}

.modal-backdrop {
  //gray-700, #627881 with .75 opacity
  background-color: rgba(98, 120, 129, .75);

  &.show {
    opacity: 1;
  }
}

.close {
  cursor: pointer;
}
