.badge-gray {
  background-color: $gray-300;
}

.badge-gray-200 {
  background-color: $gray-300;
}

.badge {
  font-family: $font-family-sofia-pro;
  line-height: $badge-line-height;
}

.badge-small {
  padding: $badge-small-padding-y $badge-small-padding-x;
  font-size: $font-size-sm;
}

.badge-large {
  padding: $badge-large-padding-y $badge-large-padding-x;
  font-size: $font-size-base;
}

.badge-radius-lg {
  border-radius: $badge-radius-lg;
}

.badge-accent-lighter {
  background-color: $accent-lighter;

  &[href] {
    &:hover {
      text-decoration: none;
      background-color: $accent-light;
    }
  }
}

.badge-primary-lighter {
  color: $primary;
  background-color: $primary-light;
}

.badge-intermediate {
  opacity: .7;
}

.badge-margin-xs {
  margin: 2px;
}

.badge-notification {
  width: 8px;
  height: 8px;
  background: $gray-300;
  border-radius: 4px;
}
