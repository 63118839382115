$category-list-max-height: 35px !default;
$vertical-list-max-height: 200px !default;
$vertical-list-max-height-lg: 180px !default;

.category-list {
  @include toggle-list($category-list-max-height);
}

.toggle-vertical-list {
  @include toggle-list($vertical-list-max-height, 400ms);
  @include media-breakpoint-up(lg) {
    max-height: $vertical-list-max-height-lg;
  }
}

.sd-toggled + .navigation-list-toggle {
  @include arrow("after", $gray-800, bottom);
  @include arrow-rotate("after", 180deg);

  color: $gray-800 !important; // stylelint-disable-line declaration-no-important
  &:hover {
    color: $gray-700 !important; // stylelint-disable-line declaration-no-important
  }

  &::after {
    margin-left: .25rem;
  }
}
