.text-shadow {
  text-shadow: 0 2px 12px rgba(0, 0, 0, .2);
}

.text-decoration-none {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.font-proxima-nova {
  font-family: $font-family-proxima-nova;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-sm-30 {
  @include media-breakpoint-down(md) {
    font-size: 30px;
  }
}

@include media-breakpoint-up(md) {
  .p-lg-base {
    font-size: $font-size-base;
    line-height: $line-height-base;
  }
}

.underline {
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-decoration-color: $accent;
  text-underline-offset: 3px;
}
