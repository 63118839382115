.sd-pnotify {
  position: fixed;
  z-index: 9999;
  box-shadow: $box-shadow-lg;

  .alert {
    margin-bottom: 0;
  }
}

.alert-primary {
  color: $accent;
  background-color: $accent-lighter;
  border-color: $accent-lighter;
}

.alert-success {
  color: $primary;
  background-color: $primary-light;
  border-color: $primary-light;
}

.alert-warning {
  color: $warning;
  background-color: $warning-light;
  border-color: $warning-light;
}

.alert-danger {
  color: $danger;
  background-color: $danger-light;
  border-color: $danger-light;
}

.alert-internal {
  font-weight: $font-weight-normal;
  .alert {
    margin-bottom: 0;
  }
}
