.speech-bubble {
  position: absolute;
  width: 156px;
  padding: .5rem 1rem;
  font-family: $font-family-sofia-pro;
  font-size: 14px;
  color: $gray-800;
  background: $white;
  border-radius: .4em;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, .16);
  animation: pop .2s linear 1;

  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    margin-bottom: -8px;
    margin-left: -8px;
    content: "";
    border: 8px solid transparent;
    border-top-color: $white;
    border-bottom: 0;
  }

  &.left::after {
    margin-left: -50px;
  }

  &.right::after {
    margin-left: 50px;
  }
}

@keyframes pop {
  0% {
    opacity: 0;
  }
  50%  {
    opacity: .5;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
  }
}
