.arrow-dropdown-toggle {
  position: relative;
  @include arrow-dropdown("after");

  &.arrow-dropdown-toggle-prepend {
    @include arrow-dropdown("before");
  }
}

.arrow-accent-right {
  @include arrow("after", $accent, right);
}

.arrow-accent-left {
  @include arrow("before", $accent, left);
}

.arrow-primary-right {
  @include arrow("after", $primary, right);
}

.arrow-primary-left {
  @include arrow("after", $primary, right);
  @include arrow-rotate("after", 180deg);
}

.arrow-primary-top {
  @include arrow("after", $primary, bottom);
  @include arrow-rotate("after", 180deg);
}

.arrow-accent-bottom {
  @include arrow("after", $accent, bottom);
}

.arrow-bottom {
  @include arrow("after", $text-color, bottom);
}

.arrow-top {
  @include arrow("after", $gray-800, bottom);
  @include arrow-rotate("after", 180deg);

  &.collapsed {
    @include arrow-rotate("after", 360deg);
    @include arrow("after", $text-color, bottom);
  }
}

.arrow-primary-right-rotate-90:not(.hide-arrow) {
  @include arrow("before", $primary, right);
}
.show .arrow-primary-right-rotate-90:not(.hide-arrow) {
  @include arrow("before", $primary, bottom);
}
