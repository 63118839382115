.company-profile-carousel-wrap::after {
  background: $gray-700;
}

.carousel-action-btn {
  border-color: $gray-400;

  img {
    filter: invert(56%) sepia(41%) saturate(6539%) hue-rotate(110deg) brightness(97%) contrast(80%);
  }
}

