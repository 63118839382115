.dropdown-autocomplete {
  .dropdown-menu {
    max-height: 242px;
    overflow-y: auto;
    font-size: 1.1rem;
    color: $gray-base;
  }

  .dropdown-menu .dropdown-item {
    padding: 8px 13px;
    cursor: pointer;
    box-shadow: none;

    &.first {
      background: $gray-200;
    }
  }

  .no-results .dropdown-item {
    font-size: 1.1rem;
    font-weight: 400;
    color: $gray-700;
  }
}
