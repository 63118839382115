$priceguide-margin-bottom: -3.25rem !default;
$priceguide-margin-bottom-desktop: 1.375rem !default;

.priceguide-graph {
  position: relative;
  width: 100%;
  height: 140px;
  padding-top: 1rem;
  margin-bottom: 4.75rem;
  background-image: url("../images/priceguide.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  @include media-breakpoint-up(sm) {
    height: 270px;
    padding-top: 2rem;
    margin-bottom: 2.5rem;
  }

  .title {
    font-family: $font-family-base;
    font-size: .875rem;
    color: $text-color;

    @include media-breakpoint-up(sm) {
      font-size: 1rem;
    }
  }

  .number-accent {
    font-family: $font-family-sofia-pro;
    font-size: 1rem;
    font-weight: $font-weight-bold;
    color: $accent;

    @include media-breakpoint-up(sm) {
      font-size: 1.5rem;
    }
  }

  .minimum {
    position: absolute;
    bottom: $priceguide-margin-bottom;
    left: 0;
    @include media-breakpoint-up(sm) {
      bottom: $priceguide-margin-bottom-desktop;
    }
  }

  .average {
    float: none;
    padding-top: 1rem;
    text-align: center;

    @include media-breakpoint-up(sm) {
      padding-top: 2rem;
    }
  }

  .maximum {
    position: absolute;
    right: 0;
    bottom: $priceguide-margin-bottom;
    text-align: right;

    @include media-breakpoint-up(sm) {
      bottom: $priceguide-margin-bottom-desktop;
    }
  }

  .interquartile-range {
    float: none;
    margin-top: .75rem;
    text-align: center;

    @include media-breakpoint-up(sm) {
      margin-top: 1.5rem;
    }

    .number-large {
      font-family: $font-family-sofia-pro;
      font-size: 1rem;
      font-weight: $font-weight-bold;
      color: $gray-base;

      @include media-breakpoint-up(sm) {
        font-size: 2rem;
      }
    }
  }
}
