$loading-icon-size: 36px !default;

.loading-icon {
  width: $loading-icon-size;
  height: $loading-icon-size;
  background-image: get-loading-icon($accent, $accent-lighter);
  background-size: $loading-icon-size;
}

.icon-24 {
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.icon-checkmark {
  background-image: $custom-icon-check-accent;
}
