.work-areas-selection {

  .custom-control {
    padding-left: 1rem;
  }

  .custom-control-label {
    padding: 0 .5rem;
    font-size: 1rem;

    &::before {
      margin: 0 .5rem;
    }

    &::after {
      margin: 0 .5rem;
    }
  }
}

.input-group-form {
  .form-group {
    margin-bottom: 0;
  }
}

.area-tool-panel {
  .input-group {
    flex-wrap: nowrap;
  }
}

.area-list {
  .custom-control-label {
    color: $gray-700;
  }
}

.navigation {
  .btn-primary {
    &.btn {
      padding: .875rem 1.5rem;
      font-size: 1.125rem;
    }
  }

  .back-button-navigation {
    font-size: 1rem;
    color: $gray-700;
    @include media-breakpoint-down(sm) {
      margin-top: 1.5rem;
    }
  }
}

.number-of-requests {
  max-width: 116px;
}

.hero-no-obligation {
  margin-left: .125rem;
  font-family: $font-family-sofia-pro;
}

.radius-postcode {
  @include media-breakpoint-up(sm) {
    flex: 1 0 auto;
  }
}

.step-check {
  .input-icon {
    position: static;
    color: $accent;

    &.icon-valid {
      background-image: $custom-experiment-icon-valid;
    }
  }
}

.form-input-container {
  .form-control {
    padding-right: 3rem;
  }

  .input-icon {
    right: 15px;
  }
}
