.input-group {
  .ng-invalid:not(.ng-pristine) {
    border-color: $danger;

    .btn {
      border-color: $danger;
    }
  }
}

.form-error * {
  font-size: 12px;
  line-height: 1.67;
  color: $danger;
}

.label-invalid {
  color: $danger;
}

.form-control-sm ~ .input-group-append > {
  .input-group-text{
    font-size: $font-size-sm;
    line-height: $line-height-sm;
  }
}
