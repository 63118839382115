.btn {

  &.btn-link {
    font-size: $font-size-base;
    color: $accent;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.btn-hero {
  min-width: 16rem;
}

.btn-arrow {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &::after {
    $btn-arrow-icon-side-size: calc(#{$font-size-base} * #{$btn-line-height});
    display: block;
    width: $btn-arrow-icon-side-size;
    height: $btn-arrow-icon-side-size;
    padding-right: $btn-arrow-icon-side-size;
    margin-left: $btn-loading-margin-left;
    content: "";
    background: $btn-arrow-icon no-repeat;
  }
}

.btn-arrow-secondary {
  @extend .btn-arrow;

  &::after {
    background: $btn-arrow-icon-primary no-repeat;
  }
}

@media (hover) {
  .shift-up {
    margin-top: 4px;
  }

  .shift-up:hover {
    transform: translateY(-4px);
  }
}

.copy-button {
  .disabled-state-translation {
    display: none;
  }

  &.copied {
    .initial-state-translation {
      display: none;
    }

    .disabled-state-translation {
      display: inline-block;
    }
  }
}

.btn-secondary {
  border-width: 2px;

  @include button-size(calc(#{$btn-padding-y} - .125rem), calc(#{$btn-padding-x} - .125rem), $font-size-base, $btn-line-height, $btn-border-radius);

  &.btn-lg {
    @include button-size(calc(#{$btn-padding-y-lg} - .125rem), calc(#{$btn-padding-x-lg} - .125rem), $font-size-lg, $btn-line-height-lg, $btn-border-radius);
  }

  &.btn-sm {
    @include button-size(calc(#{$btn-padding-y-sm}), calc(#{$btn-padding-x-sm}), $font-size-sm, $btn-line-height-sm, $btn-border-radius);
  }
}

@include media-breakpoint-up(md) {
  .btn-md-inline-block {
    display: inline-block;
    width: auto;
  }
}

.company-coc-search-result {
  .btn-custom-select-company {
    width: max-content;

    @include media-breakpoint-up(md) {
      padding: .625rem 1rem;
    }

    @include media-breakpoint-down(sm) {
      font-size: 14px;
      height: 32px;
      line-height: 32px;
      padding: 0 1rem;
    }
  }
}
