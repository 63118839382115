$company-registration-container-padding-top: 32px !default;
$company-registration-container-md-padding-top: 40px !default;
$company-registration-container-padding-top-adjustment: calc(#{$registration-flow-header-height} + #{$company-registration-container-padding-top}) !default;
$company-registration-container-md-padding-top-adjustment: calc(#{$registration-flow-header-height} + #{$company-registration-container-md-padding-top}) !default;

.company-registration {
  height: 100%;
  padding-top: $company-registration-container-padding-top-adjustment;
  margin-top: calc(#{$registration-flow-header-height} * -1);

  label {
    font-size: $font-size-lg;
  }

  @include media-breakpoint-up(md) {
    padding-top: $company-registration-container-md-padding-top-adjustment;
  }

  &.company-registration-confirmation {
    padding-top: $registration-flow-header-height;
  }

  .multi-step-container {
    position: relative;
    display: block;
    height: 100%;

    .areas-select {
      max-height: 600px;
      overflow-y: auto;
    }
  }
}
