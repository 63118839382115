.accordion {
  .card-body {
    background-color: $white;

    .faq-answer {
      padding-top: 0;
      padding-bottom: 1rem;
    }
  }

  .card-header {
    box-shadow: none;

    .btn {
      border: 0;
    }
  }

}
