// List group check mark
$list-group-check-mark-padding-left: 1rem !default;
$list-group-check-mark-icon-width: 1.25rem !default;
$list-group-check-mark-icon-height: 1.25rem !default;
$list-group-check-mark-font-size-mobile: $font-size-base !default;
$list-group-check-mark-font-size-desktop: $font-size-lg !default;
$list-group-check-mark-line-height-mobile: $line-height-base !default;
$list-group-check-mark-item-margin-bottom: .25rem !default;
$list-group-check-mark-line-height-desktop: $line-height-lg !default;

.list-group {
  .list-group-item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: .5rem 1rem;
    margin-bottom: 0;
    border: 0;
    border-radius: 0;

    &:first-of-type {
      padding-top: 1rem;
    }
    &:last-of-type {
      padding-bottom: 1rem;
    }

    &.link-container {
      padding-top: 0;
      border: 0;
      .panel-link {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-family: $font-family-sofia-pro;
        line-height: 1.42;
        color: $gray-700;
        cursor: pointer;
        border-bottom: 1px solid $gray-300;

        &::after {
          display: inline-block;
          width: $arrow-dropdown-size-y;
          height: $arrow-dropdown-size-x;
          margin-top: 3px;
          content: "";
          background: get-arrow-dropdown-icon($gray-700, right) no-repeat;
        }

        &:hover::after {
          background: get-arrow-dropdown-icon($gray-800, right) no-repeat;
        }
      }
    }
  }

  &.cards {
    .list-group-item {
      flex-direction: column;
    }
  }

  &.smaller-text {
    list-style: none;

    li {
      margin-bottom: 1rem;
      font-size: $font-size-sm;
    }
  }

  .card-header {
    padding: 0;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    border: 0;
    box-shadow: none;
  }

  .card-body {
    padding: 0;
    font-size: $font-size-sm;
  }

  &.no-border .list-group-item {
    border: 0;
    box-shadow: none;
  }

  > button {
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    color: $gray-700;

    &:hover {
      color: $gray-800;
    }
  }
}

.list-bordered {
  margin-bottom: 0;
  list-style-type: none;

  li {
    display: inline-block;
    padding: 0 10px;

    a {
      font-family: $font-family-sofia-pro;
      font-size: .8125rem;
      color: $text-color;
    }
  }

  li + li {
    border-left: 1px solid $gray-400;
  }
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  background-color: transparent;
}

/**
Below is the timeline layout
*/

.timeline-vertical {
  padding-left: 47px;
  margin-top: 1rem;
  margin-right: 1rem;

  &::before {
    position: absolute;
    top: 6.1rem;
    bottom: 3.6rem;
    left: .6rem;
    width: .15rem;
    margin-left: 25px;
    content: " ";
    background-color: $accent-light;
  }

  .timeline-item .timeline-badge {
    top: 1rem;
    left: 0;
    float: left;
    width: 42px;
    height: 42px;
  }

  .timeline-item {
    @extend .d-flex;
    @extend .align-items-center;

    width: auto;
    margin-bottom: 1rem;
    margin-left: -2rem;
    overflow: hidden;
  }

  .timeline-item .timeline-panel {
    margin-left: 1rem;
    font-size: $font-size-sm;
    color: $gray-800;
  }
}

.list-group.list-group-item.link-container {
  padding-top: .5rem;
  border: 0;

  .panel-link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: .5rem;
    padding-bottom: 1rem;
    font-family: $font-family-sofia-pro;
    line-height: 1.7;
    color: $gray-700;
    text-decoration: none;
    cursor: pointer;
    border-bottom: 1px solid $gray-300;

    &:hover {
      color: $gray-800;
    }

    &::after {
      display: inline-block;
      width: $arrow-dropdown-size-y;
      height: $arrow-dropdown-size-x;
      margin: .3rem 0;
      content: "";
      background: get-arrow-dropdown-icon($gray-700, right) no-repeat;
    }

    &:hover::after {
      background: get-arrow-dropdown-icon($gray-800, right) no-repeat;
    }
  }
}

.list-group-check {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  list-style: none;

  .list-group-check-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-left: calc(#{$list-group-check-mark-padding-left} + #{$list-group-check-mark-icon-width});
    margin-bottom: 0;
    font-size: $list-group-check-mark-font-size-mobile;
    line-height: $line-height-base;
    border: 0;

    &::before {
      position: absolute;
      top: calc((#{$list-group-check-mark-font-size-mobile} * #{$list-group-check-mark-line-height-mobile} - #{$list-group-check-mark-icon-height}) / 2);
      left: 0;
      display: inline-block;
      width: $list-group-check-mark-icon-width;
      height: $list-group-check-mark-icon-height;
      content: "";
      border: 0;
    }

    @include media-breakpoint-up(md) {
      font-size: $list-group-check-mark-font-size-desktop;
      line-height: $list-group-check-mark-line-height-desktop;
      &::before {
        top: calc((#{$list-group-check-mark-font-size-desktop} * #{$list-group-check-mark-line-height-desktop} - #{$list-group-check-mark-icon-height}) / 2);
      }
    }

    &.list-group-check-item-primary::before {
      background: $custom-icon-check-primary no-repeat;
      background-size: $list-group-check-mark-icon-width $list-group-check-mark-icon-height;
    }

    &.list-group-check-item-accent::before {
      background: $custom-icon-check-accent no-repeat;
      background-size: $list-group-check-mark-icon-width $list-group-check-mark-icon-height;
    }

    &.list-group-check-item-gray-500::before {
      background: $custom-icon-check-gray-500 no-repeat;
      background-size: $list-group-check-mark-icon-width $list-group-check-mark-icon-height;
    }

    &:not(:last-of-type) {
      margin-bottom: $list-group-check-mark-item-margin-bottom;
    }

    &.list-group-check-item-sm {
      padding-left: 1.5rem;
      margin-bottom: .75rem;
      font-size: $font-size-sm;

      &::before {
        background-size: .75rem .75rem;
      }
    }
  }
}
