.loading-box {

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  background-image: $custom-icon-loading-primary;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 48px;

  > div {
    opacity: .2;
  }
}
