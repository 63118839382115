@include media-breakpoint-up(md) {
  .justify-content-md-end {
    justify-content: end;
  }
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 25;
  display: flex;
}

.d-soft-flex {
  display: flex;
}

.flex-align-bottom-right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.gap-0_5 {
  gap: 0.5rem;
}
