// Card image panel that sits on top of text
$text-carousel-panel-left-corner-image-top: -15px !default;
$text-carousel-panel-left-corner-image-left: $text-carousel-panel-left-corner-image-top !default;
$text-carousel-panel-left-corner-image-width: 40px !default;
$text-carousel-panel-left-corner-image-height: $text-carousel-panel-left-corner-image-width !default;
$text-carousel-panel-left-corner-image-top-desktop: -20px !default;
$text-carousel-panel-left-corner-image-left-desktop: $text-carousel-panel-left-corner-image-top-desktop !default;
$text-carousel-panel-left-corner-image-width-desktop: 56px !default;
$text-carousel-panel-left-corner-image-height-desktop: $text-carousel-panel-left-corner-image-width !default;
$text-carousel-panel-height: 400px !default;
$text-carousel-panel-height-desktop: 430px !default;

// Card image Carousel controls
$text-carousel-controls-container-height: 62px !default;
$text-carousel-controls-container-box-shadow: $box-shadow-lg !default;
$text-carousel-controls-container-width: 140px !default;
$text-carousel-controls-border-radius: 31px !default;
$text-carousel-control-action-padding-x: 20px !default;
$text-carousel-control-img-width: 32px !default;
$text-carousel-control-img-height: $text-carousel-control-img-width !default;
$text-carousel-control-img-hover-grow: 6px !default;

.text-carousel {
  @extend .card-image-container;

  .carousel-item {
    height: 100%;
    transition: none;

    &.carousel-item-right,
    &.carousel-item-left {
      @include transition(transform .15s ease, opacity .15s ease);
      opacity: 0;
    }

    &.carousel-item-next,
    &.carousel-item-prev {
      opacity: 0;
      &.carousel-item-right,
      &.carousel-item-left {
        opacity: 1;
      }
    }
  }

  .carousel,
  .carousel-inner {
    height: 100%;
  }

  .panel {
    z-index: 1;
    height: $text-carousel-panel-height;
    margin-bottom: $text-carousel-controls-container-height / 2; // compensate for the controls on the bottom

    @include media-breakpoint-up(md) {
      height: $text-carousel-panel-height-desktop;
    }
  }

  .text-carousel-panel-left-corner-image {
    position: absolute;
    top: $text-carousel-panel-left-corner-image-top;
    left: $text-carousel-panel-left-corner-image-left;
    padding: inherit;

    @include media-breakpoint-up(lg) {
      top: $text-carousel-panel-left-corner-image-top-desktop;
      left: $text-carousel-panel-left-corner-image-left-desktop;
    }

    img {
      width: $text-carousel-panel-left-corner-image-width;
      height: $text-carousel-panel-left-corner-image-height;

      @include media-breakpoint-up(lg) {
        width: $text-carousel-panel-left-corner-image-width-desktop;
        height: $text-carousel-panel-left-corner-image-height-desktop;
      }
    }
  }

  .text-carousel-controls-container {
    position: absolute;
    bottom: -$text-carousel-controls-container-height / 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: $text-carousel-controls-container-width;
    height: $text-carousel-controls-container-height;
    background-color: $white;
    border-radius: $text-carousel-controls-border-radius;
    box-shadow: $text-carousel-controls-container-box-shadow;

    .text-carousel-control-prev,
    .text-carousel-control-next {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      height: 100%;
      border-radius: $text-carousel-controls-border-radius;

      img {
        display: block;
        width: $text-carousel-control-img-width;
        height: $text-carousel-control-img-height;
      }

      &:hover {
        cursor: pointer;

        img {
          display: block;
          width: $text-carousel-control-img-width + $text-carousel-control-img-hover-grow;
          height: $text-carousel-control-img-height + $text-carousel-control-img-hover-grow;
          transition: width .2s linear;
        }
      }
    }

    .text-carousel-control-prev {
      padding-left: $text-carousel-control-action-padding-x;
      border-top-left-radius: $text-carousel-controls-border-radius;
      border-bottom-left-radius: $text-carousel-controls-border-radius;

      img {
        margin-right: auto;
        transform: rotate(180deg);
      }
    }

    .text-carousel-control-next {
      padding-right: $text-carousel-control-action-padding-x;
      border-top-right-radius: $text-carousel-controls-border-radius;
      border-bottom-right-radius: $text-carousel-controls-border-radius;

      img {
        margin-left: auto;
      }
    }
  }
}
