.step-progress-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  font-family: $font-family-sofia-pro;

  .step {
    z-index: $zindex-progress-bar-step;
    display: flex;
    align-items: center;
    color: $accent;

    &.inactive {
      color: $gray-500;
      .circle {
        color: $gray-500;
        border-color: $gray-500;

        &::after {
          background-color: $gray-200;
        }
      }
    }

    .step-label {
      margin-left: 10px;
    }
  }

  .circle {
    position: relative;
    z-index: auto; //overwrite
    flex-shrink: 0;

    @include media-breakpoint-up(lg) {
      width: $circle-sm-width;
      height: $circle-sm-height;
    }

    &::after {
      position: absolute;
      top: 2px;
      left: 2px;
      z-index: -1; // Can't be a different value since it's placed on a pseudo element
      width: $circle-sm-width;
      height: $circle-sm-height;
      content: "";
      background-color: $accent-lighter;
      border-radius: $border-radius-circle;

      @include media-breakpoint-up(lg) {
        width: $circle-sm-width;
        height: $circle-sm-height;
      }
    }
  }

  .step-separator {
    flex-grow: 1;
    margin-top: $line-bold / 2;
    margin-right: $progress-bar-line-margin;
    margin-left: $progress-bar-line-margin;
    border-top: $line-bold dashed $accent-lighter;
  }
}
