$arrow-icon-big-width: 1rem !default;
$arrow-icon-big-height: 1rem !default;
$arrow-icon-big: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='[[fillColor]]' fill-rule='nonzero' d='M8 10.841l6.675-5.72a.5.5 0 0 1 .65.759l-7 6a.5.5 0 0 1-.65 0l-7-6a.5.5 0 1 1 .65-.76L8 10.841z'/%3E%3C/svg%3E%0A") !default;

.accordion {
  .card {
    white-space: normal;
    border: 0;
    border-radius: 0;
    box-shadow: $faq-card-box-shadow;
  }

  .card-header {
    padding: 0;
    border-radius: 0;

    .btn {
      display: flex;
      align-items: start;
      justify-content: space-between;
      padding: 1rem 1.25rem;
      margin: 0;
      color: $gray-800;
      text-align: left;
      text-decoration: none;
      white-space: normal;
      box-shadow: none;

      &:hover {
        text-decoration: none;
      }

      .arrow-icon::after {
        display: inline-block;
        width: $arrow-icon-big-width;
        height: $arrow-icon-big-height;
        content: "";
        background: str-replace(str-replace($arrow-icon-big, "[[fillColor]]", $primary), "#", "%23") no-repeat;
        border: 0;
      }
    }

    .btn[aria-expanded="true"] .arrow-icon::after {
      transform: rotate(180deg);
    }
  }

  .card-body {
    font-size: $font-size-sm;
    color: $gray-700;
    background-color: $gray-150;

    .faq-answer {
      padding: .8125rem 1.25rem;

      @include media-breakpoint-up(md) {
        padding-right: 0;
      }
    }

    p {
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    padding-left: 1rem;
  }
}

@include media-breakpoint-down(md) {
  .menu-collapse {
    height: 0;
    overflow: hidden;
    transition: all 250ms ease-in-out;
  }

  .menu-expand {
    height: 110px;
  }
}
